/** @flow
 * StratoDem Analytics : join-the-team
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';

import {Layout, SEO} from '../../components';

import './join-the-team.scss';

export default class DataScientist extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Data Scientist - Real Estate"
          keywords={['stratodem analytics']}
          description="Data Scientist - Real Estate"
        />
        <div className="sd-section join-the-team posting">
          <div className="inner">
            <h3>Data Scientist &ndash; Real Estate</h3>

            <h4>About this job</h4>
            <p>
              StratoDem Analytics is currently seeking a Data Scientist who will help
              design and build data analytics pipelines, scalable Python-based APIs, and new
              data products for the real estate field. This <strong>remote position</strong> is for someone seeking to
              work in a remote environment to develop and improve real estate analysis using
              statistical and machine learning methods.
            </p>

            <h4>The company: StratoDem Analytics</h4>
            <p>
              StratoDem Analytics is a data science firm building predictive models on massive
              data for better US local-market knowledge. We help some of the
              largest private equity funds and real estate organizations in the US make better
              portfolio-level and business decisions based on a fundamentally better understanding
              of local-market economic risk and demographic outlooks.
            </p>

            <h4>Responsibilities</h4>
            <p>
              This role will involve:
            </p>
            <ul>
              <li>Designing and developing as a team contributor to StratoDem Analytics products and client deliveries</li>
              <li>Building within, and improving, the StratoDem Analytics analytics pipeline</li>
              <li>Writing clean, fully-tested and well-documented code in Python 3 with pandas, NumPy, Dask, TensorFlow, scikit-learn and Dash</li>
              <li>Working directly with company founders on model building, database design and deployment strategies</li>
              <li>Working directly with clients to identify pain points and opportunities in pre-existing analytics pipelines and build or improve StratoDem Analytics products</li>
            </ul>

            <h4>Qualifications</h4>
            <p>
              The ideal candidate for this role has strong Python expertise and experience with the following:
            </p>
            <ul>
              <li>Python server-side frameworks such as Django or Flask</li>
              <li>Complex modeling of large, unclean data sets with the Python data science stack of pandas, NumPy, scikit-learn, Dask and TensorFlow</li>
              <li>Geospatial analysis (e.g., spatial analytics with PostGIS)</li>
              <li>Writing testable, component-based code and writing tests with complete logical coverage of code</li>
              <li>Git and GitHub workflows in a remote team environment</li>
              <li>SQL queries and relational databases</li>
            </ul>

            <p>In addition, the following experience is a big plus:</p>
            <ul>
              <li>Advanced financial statistics and probability</li>
              <li>GIS software and algorithms</li>
              <li>AWS</li>
            </ul>

            <p>Source code samples (preferably a link to a GitHub account) required. MA, MS or equivalent work experience preferred.
              To apply, send cover note, resume, and ideally a link to a GitHub account to <a href="mailto:jobs@stratodem.com">jobs@stratodem.com</a></p>
          </div>
        </div>
      </Layout>);
  }
}
